<template>
  <div class="content">
    <div>
      <h1 class="text-white">403</h1>
      <h4 class="text-black">{{$t('Unauthorized')}}</h4>
      <Button @click="goback" class="text-center" :title="$t('back')"></Button>

    </div>
  </div>
</template>
<script>
import Button from "../Current/Components/Button"
export default {
  components:{
    Button
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
h1 {
  font-size: 65px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding-top: 50%; */
}
@media (max-width: 992px) {
  .content {
    padding-top: 200px;
  }
}
.login .image {
  top: 14.7%;
}
</style>